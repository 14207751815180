<template>
  <div class="row items-center">
    <q-btn
      dense
      flat
      icon="arrow_back_ios"
      v-close-popup
      class="q-ml-md desktop-hide text-right"
    >
      <q-space />
    </q-btn>
  </div>
</template>


<style lang="scss" scoped>
.q-btn {
  color: black;
  font-size: 12px;
}
</style>
